import loadable from "@loadable/component";
import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

export const history = createBrowserHistory();

const Login = loadable(() => import("pages/Login/Login"), {
  fallback: <div>Carregando...</div>
});

const Dashboard = loadable(() => import("pages/Dashboard/Dashboard"), {
  fallback: <div>Carregando...</div>
});

const DashboardPeticao = loadable(() => import("pages/DashboardPeticao"), {
  fallback: <div>Carregando...</div>
});

const Peticao = loadable(() => import("pages/DashboardPeticao/Peticao"), {
  fallback: <div>Carregando...</div>
});

const Contas = loadable(() => import("pages/Contas/Contas"), {
  fallback: <div>Carregando...</div>
});

const ContaDetalhe = loadable(() => import("pages/Contas/ContaDetalhe"), {
  fallback: <div>Carregando...</div>
});

const MembroDetalhe = loadable(() => import("pages/Contas/MembroDetalhe"), {
  fallback: <div>Carregando...</div>
});
const GrupoDetalhe = loadable(() => import("pages/Grupos/GrupoDetalhe"), {
  fallback: <div>Carregando...</div>
});

const Usuarios = loadable(() => import("pages/Usuarios/Usuarios"), {
  fallback: <div>Carregando...</div>
});

const NovaPeticao = loadable(() => import("pages/Peticao/NovaPeticao"), {
  fallback: <div>Carregando...</div>
});

const Grupos = loadable(() => import("pages/Grupos/Grupos"), {
  fallback: <div>Carregando...</div>
});

const Cursos = loadable(() => import("pages/Cursos/Cursos"), {
  fallback: <div>Carregando...</div>
});

const CursosDetalhe = loadable(() => import("pages/Cursos/CursosDetalhe"), {
  fallback: <div>Carregando...</div>
});

function AppRouter() {
  return (
    <Router history={history}>
      {/* Rotas que nao precisam de autenticacao */}
      <Route path="/login" exact component={Login} />

      {/* Rotas que autenticadas */}
      <PrivateRoute path="/" exact component={Dashboard} />
      <PrivateRoute path="/peticao" exact component={Peticao} />
      <PrivateRoute path="/contas" exact component={Contas} />
      <PrivateRoute path="/contas/:id" exact component={ContaDetalhe} />
      <PrivateRoute path="/membros/:id" exact component={MembroDetalhe} />
      <PrivateRoute path="/grupos" exact component={Grupos} />
      <PrivateRoute path="/grupos/:id" exact component={GrupoDetalhe} />
      <PrivateRoute path="/cursos" exact component={Cursos} />
      <PrivateRoute path="/cursos/:id" exact component={CursosDetalhe} />
      <PrivateRoute
        path="/dashboardPeticao"
        exact
        component={DashboardPeticao}
      />
      <PrivateRoute path="/novaPeticao" exact component={NovaPeticao} />
      <PrivateRoute path="/editarPeticao/:id" exact component={NovaPeticao} />
      <PrivateRoute path="/usuarios" exact component={Usuarios} />
    </Router>
  );
}

export default AppRouter;
