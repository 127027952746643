import { ActionEnum } from '../enum/ActionEnum.js';

let initialState = {
    lastUpdade: null,
    sexo: 'AMBOS',
    visaoSexo: true
}

export const peticao = (state = initialState, action) => {
    switch (action.type) {
        case ActionEnum.UPDATE_PETICAO:
            return {
                ...state,
                ...action.data
            }
        case ActionEnum.CLEAN_PETICAO:
            return {
                ...initialState
            }
        default:
            return state;
    }
}