export const usuarios = (state = null, action) => {
  switch (action.type) {
    case "LIST_USERS":
      return action.payload;
    case "APPEND_USER":
      return [...state, action.payload];
    default:
      return state;
  }
};
