import React from "react";
import { Route, Redirect,  } from "react-router-dom";
import { isAuthenticated } from "core/prevFunctions";

export const PrivateRoute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={renderProps =>
      isAuthenticated() ? (
        <Component {...renderProps} {...props}/>
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: renderProps.location } }}
        />
      )
    }
  />
);
