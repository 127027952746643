import { combineReducers } from "redux";
import { auth } from "./AuthReducer";
import { peticao } from "./PeticaoReducer";
import { usuarios } from "./UserReducer";
// import { omniSearch } from "./OmniSearchReducer";
import { contas, sessao } from "./ContasReducer";
import { grupos } from "./GruposReducer";

import utils from "./utils";

export default combineReducers({
  auth: auth,
  peticao: peticao,
  usuarios,
  // omniSearch,
  utils,
  contas,
  grupos,
  sessao
});
