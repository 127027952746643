import React from "react";
import moment from "moment";
import _style from 'helpers/style'
import style_word from 'helpers/style_word'
import {
  tiposPeticao,
  tiposDeBeneficio,
  // statusPeticao,
  tagsPeticao,
  statusCobranca,
  statusTransacao,
  API_HOST,
  categorias
} from "core/Constants";

const daysInMonth = (year, month) => {
  // return 32 - new Date(iYear, iMonth, 32).getDate();
  return new Date(year, month, 0).getDate();
};

const mergeArrayWithProp = (a, b, prop) => {
  const reduced = a.filter(
    aitem => !b.find(bitem => aitem[prop] === bitem[prop])
  );
  return reduced.concat(b);
};

const checkContribuicoes = (vinculo, salariosDeContribuicao) => {
  let pisos = [],
    tetos = [];
  if (salariosDeContribuicao.length) {
    pisos = vinculo.contribuicoes
      .filter(item => {
        const find = salariosDeContribuicao.find(
          sal => sal.competencia === item.competencia
        );
        if (find) return item.salarioDeContribuicao < find.piso;
        return false;
      })
      .map((item, index) => item.competencia);
    tetos = vinculo.contribuicoes
      .filter(item => {
        const find = salariosDeContribuicao.find(
          sal => sal.competencia === item.competencia
        );
        if (find) {
          return item.salarioDeContribuicao > find.teto;
        } else {
          return false;
        }
      })
      .map((item, index) => item.competencia);
  }
  return {
    pisos: pisos,
    tetos: tetos
  };
};

const getMoedaDaEpoca = competencia => {
  const comp = moment(competencia.competencia + "-01", "YYYY-MM-DD");
  if (
    comp.isBetween(
      moment("01/11/1942", "DD/MM/YYYY"),
      moment("12/02/1967", "DD/MM/YYYY")
    )
  ) {
    return "Cr$";
  } else if (
    comp.isBetween(
      moment("13/02/1967", "DD/MM/YYYY"),
      moment("14/05/1970", "DD/MM/YYYY")
    )
  ) {
    return "NCr$";
  } else if (
    comp.isBetween(
      moment("15/05/1970", "DD/MM/YYYY"),
      moment("27/02/1986", "DD/MM/YYYY")
    )
  ) {
    return "Cr$";
  } else if (
    comp.isBetween(
      moment("28/02/1986", "DD/MM/YYYY"),
      moment("15/02/1989", "DD/MM/YYYY")
    )
  ) {
    return "Cz$";
  } else if (
    comp.isBetween(
      moment("16/02/1989", "DD/MM/YYYY"),
      moment("15/03/1990", "DD/MM/YYYY")
    )
  ) {
    return "NCz$";
  } else if (
    comp.isBetween(
      moment("16/03/1990", "DD/MM/YYYY"),
      moment("31/07/1993", "DD/MM/YYYY")
    )
  ) {
    return "Cr$";
  } else if (
    comp.isBetween(
      moment("01/08/1993", "DD/MM/YYYY"),
      moment("30/06/1994", "DD/MM/YYYY")
    )
  ) {
    return "CR$";
  } else if (comp < moment("01/07/1994", "DD/MM/YYYY")) {
    return "R$";
  } else {
    return "R$";
  }
};

const titulos = {
  APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO:
    "Aposentadoria por Tempo de Contribuição",
  APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_85_95:
    "Aposentadoria por contribuição 85/95",
  APOSENTADORIA_POR_IDADE: "Aposentadoria por Idade",
  APOSENTADORIA_ESPECIAL: "Aposentadoria especial",
  APOSENTADORIA_POR_INVALIDEZ: "Aposentadoria por Invalidez",
  AUXILIO_DOENCA: "Auxílio-Doença",
  AUXILIO_ACIDENTE: "Auxílio-Acidente",
  APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS:
    "Aposentadoria por Tempo de Contribuição (Pontos)",
  APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PROFESSOR:
    "Aposentadoria por Tempo de Contribuição - Professor",
  APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS_PROFESSOR:
    "Aposentadoria por Tempo de Contribuição - Professor (Pontos)",
  APOSENTADORIA_POR_IDADE_RURAL: "Aposentadoria por idade (Rural)",
};

const listBoxProps = {
  apto: {
    background: "#60d14a1e",
    border: "#60d14a",
    badge: "#60d14a"
  },
  "nao-apto": {
    background: "#EC105119",
    border: "#ec1051",
    badge: "#ec1051"
  },
  atencao: {
    background: "#D3CA9219",
    border: "#d3ca92",
    badge: "#beb47b"
  }
};

const relatorioHeaderInfo = cta => {
  return (
    <div
      id="noprint"
      className="flexCenter no-print"
      style={{
        width: 1000,
        margin: "auto",
        marginBottom: 40,
        borderBottom: "dashed",
        paddingBottom: 20
      }}
    >
      <div style={{ margin: "auto" }}>
        Para imprimir o relatório em papel, clique no botão abaixo e selecione
        uma impressora válida no seu computador
        <br />
        Para salvar o relatório em PDF, clique no botão abaixo e:
        <br />- no Chrome: em Destino e selecione "Salvar como PDF";
        <br />- no Firefox: em Impressora, Nome:, selecione "Microsoft Print to
        PDF";
        <br />
        - no Microsoft Edge: em "Impressora" e selecione "Microsoft Print to
        PDF";
        <br />- no Internet Explorer: em "Selecionar impressora" e selecione
        "Microsoft Print to PDF";
        <br />- no Safari: vá em "PDF" ao lado de "Mostrar Detalhes" e selecione
        "Salvar como PDF";
        <br />
        <div style={{ textAlign: "center" }}>
          <button
            id="buttonPrint"
            style={{
              background: "#5fa1d8",
              marginTop: 20,
              color: "#fff",
              backgroundCcolor: "#5fa1d8",
              cursor: "pointer",
              border: "1px solid transparent",
              padding: "0 15px",
              fontSize: 16,
              borderRadius: 4,
              height: 38
            }}
          >
            {cta
              ? cta
              : "Clique aqui para imprimir ou salvar o relatório em PDF"}
          </button>
        </div>
      </div>
    </div>
  );
};

const getHtmlWithStyleToPrint = (html, title = `Relatório`) => {
  const style = _style;
  return `<html><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><title>${title}</title>${style}${html}</html>`;
};

const getCategoriasParaCalculo = () => {
  return categorias.filter(categoria => {
    if (['ATIVIDADE_ESPECIAL','INCAPACIDADE_PARA_TRABALHO', 'ATIVIDADE_CONCOMITANTE'].includes(categoria.tag)) {
      return false
    }
    return true
  });
}

const getTipoBeneficioDesc = tipo => {
  const tipos = tiposDeBeneficio;
  return tipos.find(i => i.value === tipo).desc;
};

const getTiposPeticaoDesc = tipo => {
  const tipos = tiposPeticao;
  const tipoF = tipos.find(i => i.value === tipo);
  return tipoF ? tipoF.desc : tipo;
};

const getTagsDesc = tipo => {
  const tipos = tagsPeticao;
  const tipoF = tipos.find(i => i.tag === tipo);
  return tipoF ? tipoF.desc : tipo;
};

const getStatusCobrancas = status => {
  const sc = statusCobranca;
  const st = sc.find(i => i.status === status);
  return st ? st.desc : st;
};

const getStatusTransacoes = status => {
  const sc = statusTransacao;
  const st = sc.find(i => i.status === status);
  return st ? st.desc : st;
};

const buildVinculosParaCnis = vinculos => {
  return vinculos.map((item, index) => {
    if (!item.fim && item.contribuicoes.length === 0) {
      console.error(
        "Data fim null e contribuicoes null, index = " + index,
        item
      );
      // return {
      //   ...item,
      //   alertas: [...item.alertas, 'VINCULO_SEM_DATA_DE_FIM']
      // };
    }
    if (!item.fim && item.contribuicoes.length) {
      const lastElement = item.contribuicoes[item.contribuicoes.length - 1];
      const lastDay = daysInMonth(
        lastElement.competencia.substring(0, 4),
        lastElement.competencia.substring(5, 8)
      );
      return {
        ...item,
        fimGerado: true,
        fim: lastElement.competencia + "-" + lastDay
      };
    }

    return item;
  });
};

const getUrlWithSlug = slug => {
  let url = `https://${slug}.prev.app`;
  if (window.location.hostname.indexOf("localhost") > -1) {
    url = `http://${slug}.localhost:3000`;
  } else if (API_HOST.indexOf("homolog") > 0) {
    url = `https://${slug}.prevhml.app`;
  }
  return url;
};

const getUrlToRedirectWithToken = (slug, token) => {
  let url = `https://${slug}.prev.app`;
  if (window.location.hostname.indexOf("localhost") > -1) {
    url = `http://${slug}.localhost:3000`;
  } else if (API_HOST.indexOf("homolog") > 0) {
    url = `https://${slug}.prevhml.app`;
  }

  let fullUrl = `${url}?redirect=${window.btoa(token)}`;
  return fullUrl;
};

const parseJwt = token => {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
};

const getSlugFromUrl = () => {
  const split = window.location.host.split(".");
  if (window.location.hostname.indexOf("localhost") > -1) {
    if (split.length > 1) {
      return window.location.host.split(".")[0];
    }
  } else {
    if (split.length > 2) {
      return window.location.host.split(".")[0];
    }
  }
  return null;
};

const getSegmentObject = user => {
  return {
    ...user,
    address: {
      ...user.endereco,
      city: user.endereco ? user.endereco.cidade: null,
      country: user.endereco ? user.endereco.pais || `Brasil` : null,
      postalCode: user.endereco ? user.endereco.cep : null,
      state: user.endereco ? user.endereco.uf : null,
      street: user.endereco ? user.endereco.endereco : null
    },
    name: user.nome,
    username: user.email,
    description: `${user.privilegio}`,
    company: {
      name: user.escritorio.nome,
      id: user.escritorio.id,
      employee_count: user.escritorio.numeroDeFuncionarios
    },
    avatar: user.urlImagemPerfil,
    gender: user.sexo,
    phone: user.celular,
    organization: user.escritorio ? user.escritorio.nome : '',
    title: user.privilegio,
    // birthday: new Date(user.dataDeNascimento),
    dataDoUltimoAcesso: new Date(user.dataDoUltimoAcesso)
    // company: {
    //   name:
    // }
  };
};

const isMobile = () => {
  return window.innerWidth < 480
}

const isLayoutMenor = () => {
  return window.innerWidth < 1400
}

const getGrupoFromCalculoByIndex = (calculo, index) => {
  return calculo.find(item => item.grupo === `${index}`);
}

const addStyleWord = html => {
  const finalHtml = 
  `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>\
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\
    <head>${style_word}</head>\
    ${html.replace(/padding/gi,'margin')}
    </html>`;
  return finalHtml;
}

const export2Doc = (html, filename = '') => {
  var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
  });
  
  // Specify link url
  var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
  
  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);
  
  if(navigator.msSaveOrOpenBlob ){
      navigator.msSaveOrOpenBlob(blob, filename);
  }else{
      // Create a link to the file
      downloadLink.href = url;
      
      // Setting the file name
      downloadLink.download = filename;
      
      //triggering the function
      downloadLink.click();
  }
  
  document.body.removeChild(downloadLink);
}

const isObject = (obj) => {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
}

export {
  daysInMonth,
  titulos,
  listBoxProps,
  mergeArrayWithProp,
  getMoedaDaEpoca,
  checkContribuicoes,
  getHtmlWithStyleToPrint,
  relatorioHeaderInfo,
  getTipoBeneficioDesc,
  getTiposPeticaoDesc,
  getTagsDesc,
  buildVinculosParaCnis,
  getUrlToRedirectWithToken,
  parseJwt,
  getSlugFromUrl,
  getUrlWithSlug,
  getStatusCobrancas,
  getStatusTransacoes,
  getSegmentObject,
  getCategoriasParaCalculo,
  isMobile,
  isLayoutMenor,
  getGrupoFromCalculoByIndex,
  export2Doc,
  addStyleWord,
  isObject
};
