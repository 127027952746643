export const ActionEnum = {
  GET_STATUS_ONBOARDING: 'GET_STATUS_ONBOARDING',
  UPDATE_ONBOARDING: 'UPDATE_ONBOARDING',
  GET_USER: 'GET_USER',
  GET_ESCRITORIOS: 'GET_ESCRITORIOS',
  START_LOADING_ESCRITORIO: 'START_LOADING_ESCRITORIO',
  STOP_LOADING_ESCRITORIO: 'STOP_LOADING_ESCRITORIO',
  GET_WORKSPACE_ESCRITORIO: 'GET_WORKSPACE_ESCRITORIO',
  GET_WORKSPACE_ESCRITORIO_HISTORICO: 'GET_WORKSPACE_ESCRITORIO_HISTORICO',
  GET_WORKSPACE_ESCRITORIO_MEMBROS: 'GET_WORKSPACE_ESCRITORIO_MEMBROS',
  GET_WORKSPACE_ESCRITORIO_COBRANCAS: 'GET_WORKSPACE_ESCRITORIO_COBRANCAS',
  GET_WORKSPACE_ESCRITORIO_ASSINATURA: 'GET_WORKSPACE_ESCRITORIO_ASSINATURA',
  UPDATE_CNIS: 'UPDATE_CNIS',
  CLEAN_CNIS: 'CLEAN_CNIS',
  UPDATE_CONCOMITANCIA: 'UPDATE_CONCOMITANCIA',
  CLEAN_CONCOMITANCIA: 'CLEAN_CONCOMITANCIA',
  UPDATE_CALCULOS: 'UPDATE_CALCULOS',
  UPDATE_BENEFICIOS: 'UPDATE_BENEFICIOS',
  BENEFICIO_SELECIONADO: 'BENEFICIO_SELECIONADO',
  BENEFICIO_SELECIONADO_LIMPAR: 'BENEFICIO_SELECIONADO_LIMPAR',
  UPDATE_PETICAO: 'UPDATE_PETICAO',
  CLEAN_PETICAO: 'CLEAN_PETICAO',
  UPDATE_PETICAO_PREFERENCIAS: 'UPDATE_PETICAO_PREFERENCIAS',
  UPDATE_USER_FORM: 'UPDATE_USER_FORM',
  GET_ULTIMOS_CLIENTES_PETICAO: 'GET_ULTIMOS_CLIENTES_PETICAO',
  GET_ULTIMOS_SLUGS: 'GET_ULTIMOS_SLUGS',
  CLEAN_ULTIMOS_SLUGS: 'CLEAN_ULTIMOS_SLUGS'
}
