export const auth = (state = {}, action) => {
  switch (action.type) {
    case "AUTH_STEP":
      return { step: action.step, payload: action.payload };
    case "AUTHENTICATED":
      return { authenticated: true };
    case "UNAUTHENTICATED":
      return { authenticated: false };
    case "AUTHENTICATION_ERROR":
      return { error: action.payload };
    default:
      return state;
  }
};
