// import { getTiposDeArquivo } from 'services/utils';

export const LOADING_STATUS_TIPOS_ARQUIVOS = 'app/UtilsAction/LOADING_STATUS_TIPOS_ARQUIVOS';
export const TIPOS_DE_ARQUIVOS = 'app/UtilsAction/TIPOS_DE_ARQUIVOS';
export const TIPOS_DE_ARQUIVOS_ERRO = 'app/UtilsAction/TIPOS_DE_ARQUIVOS_ERRO';
export const LOADING_GERAL = 'app/UtilsAction/LOADING_GERAL';
export const TEST_PAYWALL = 'app/UtilsAction/TEST_PAYWALL';
export const COLLAPSE_MENU = 'app/UtilsAction/COLLAPSE_MENU';
export const UPDATE_OMNI_SEARCH = 'app/UtilsAction/UPDATE_OMNI_SEARCH';
export const TOGGLE_OMNI_SEARCH = 'app/UtilsAction/TOGGLE_OMNI_SEARCH';

export const loadingStatusTipoDeArquivo = status => ({ type: LOADING_STATUS_TIPOS_ARQUIVOS, status })

export const tiposDeArquivos = tipos => ({ type: TIPOS_DE_ARQUIVOS, tipos})

// export const fetchTiposDeArquivos = () => async dispatch => {
//   try {
//     dispatch(loadingStatusTipoDeArquivo(true))

//     const tipos = await getTiposDeArquivo();
//     dispatch(tiposDeArquivos(tipos));

//     dispatch(loadingStatusTipoDeArquivo(false))
//   } catch (e) {
//     console.eror(e);
//     alert("Falha ao carregar lista de tipos de arquivos, entrar novamente.");
//     dispatch(loadingStatusTipoDeArquivo(false))
//   }
// }

export const updateOmniSearch = omniSearch => ({ type: UPDATE_OMNI_SEARCH, omniSearch })
export const toggleOmniSearch = ()=> ({ type: TOGGLE_OMNI_SEARCH })
export const loadingGeral = loading => ({ type: LOADING_GERAL, loading })
export const updatePayWall = showPayWall => ({ type: TEST_PAYWALL, showPayWall})
export const testPayWall = () => (dispatch, getState) => {
  const state = getState();
  let payWall = { type: TEST_PAYWALL, showPayWall: false };
  let retorno = false;
  const { assinatura }  = state.workspaceEscritorio;
  if (assinatura) {
    const { status, acesso } = assinatura;
    const suspenso = status === 'ATIVA' && acesso === 'SUSPENSO'
    const cancelada = status === 'CANCELADA' && acesso !== 'ATIVO'
    if (suspenso || cancelada) {
      payWall = { type: TEST_PAYWALL, showPayWall: true }
      retorno = true
    }
  }
  dispatch(payWall);
  return retorno;
}

export const collapseMenu = collapse => ({ type: COLLAPSE_MENU, collapse })

