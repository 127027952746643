export const grupos = (state = { loading: true }, action) => {
  switch (action.type) {
    case "PERMISSOES":
      return {
        ...state,
        permissoesList: action.payload
      };
    case "GRUPOS_LIST":
      return {
        list: action.payload
      };
    case "GRUPO_DETALHE":
      return {
        ...state,
        detalhe: action.payload
      };
    case "GRUPO_PERMISSOES":
      return {
        ...state,
        permissoes: action.payload
      };
    case "GRUPO_MEMBROS":
      return {
        ...state,
        membros: action.payload
      };
    case "GRUPO_LOADING":
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
