import { parseJwt } from "helpers/Utils";

const getToken = () => {
  if (localStorage) {
    return localStorage.getItem("access_token");
  }
  return null;
};

const isAuthenticated = () => {
  const token = getToken();

  if (token) {
    const parsedToken = parseJwt(token);
    if (Date.now() >= parsedToken.exp * 1000) {
      removeToken();
      return false;
    }
    return true;
  }
  return false;
};

const storeToken = token => {
  if (localStorage) {
    localStorage.setItem("access_token", token);
  } else {
    console.error("localStorage error");
  }
};

const removeToken = () => {
  if (localStorage) {
    localStorage.removeItem("access_token");
  } else {
    console.error("localStorage error");
  }
};

const onlyNumbers = value => {
  return value.replace(/[^0-9]/g, "");
};

export { getToken, storeToken, onlyNumbers, removeToken, isAuthenticated };
