// export const API_HOST = "http://sandbox-previdenciarista-1907112657.us-east-1.elb.amazonaws.com";
export const API_HOST = `${process.env.REACT_APP_PREV_API}/v1`;
export const API_HOST_V2 = `${process.env.REACT_APP_PREV_API}/v2/dashboard`;
export const API_HOST_PREV_ASSINATURSA = `${process.env.REACT_APP_PREV_API}/prev-assinaturas/v1`;
export const AUTH_TOKEN = `${process.env.REACT_APP_PREV_AUTH_TOKEN}`;
export const VERCEL_REVALIDATE_TOKEN = `${process.env.REACT_APP_VERCEL_REVALIDATE_TOKEN}`;

// console.log("ENV->", process.env);
// console.log("API_HOST->", API_HOST);
// console.log("AUTH_TOKEN->", AUTH_TOKEN);

export const LAMBDA_PDF = `${process.env.REACT_APP_PREV_DASHBOARD_IAPI}/download-converter/pdf`;

export const LAMBDA_INLINE_CSS = `${process.env.REACT_APP_PREV_DASHBOARD_IAPI}/download-converter/htmlinline`;

export const tiposOperador = [
  { value: "MENOR_IGUAL", desc: "Menor ou igual a" },
  { value: "MAIOR_IGUAL", desc: "Maior ou igual a" },
];

export const tiposOrdenacaoPeticao = [
  { value: "SCORE", desc: "Relevância" },
  { value: "DATA_DE_ATUALIZACAO_DESC", desc: "Mais recentes" },
  { value: "DATA_DE_ATUALIZACAO_ASC", desc: "Mais antigas" },
];

export const statusPeticao = [
  { value: "EM_DESENVOLVIMENTO", desc: "Em desenvolvimento" },
  { value: "AGUARDANDO_APROVACAO", desc: "Aguardando aprovação" },
  { value: "APROVADO", desc: "Aprovado" },
  { value: "REPROVADO", desc: "Reprovado" },
  { value: "REVISANDO", desc: "Revisando" },
  { value: "PUBLICADO", desc: "Publicado" },
  { value: "ARQUIVADO", desc: "Desativado" },
];
export const tiposDeAcao = [
  { value: "CONCESSAO", desc: "Concessão" },
  { value: "RESTABELECIMENTO", desc: "Restabelecimento" },
  { value: "REVISAO", desc: "Revisão" },
  { value: "OUTROS", desc: "Outros" },
];

export const tipoPeriodo = [
  {
    value: "PRE_REFORMA",
    desc: "Pré-reforma",
  },
  {
    value: "TRANSICAO",
    desc: "Regra de transição",
  },
  {
    value: "REFORMA",
    desc: "Pós-reforma",
  },
];
export const subtiposDeBeneficio = [
  {
    value: "NORMAL",
    desc: "Normal",
  },
  {
    value: "PROFESSOR",
    desc: "Professor",
  },
  {
    value: "NORMAL_PONTOS",
    desc: "Pontos",
  },
  {
    value: "PROFESSOR_PONTOS",
    desc: "Pontos Professor",
  },
  {
    value: "PEDAGIO_50",
    desc: "Pedágio 50%",
  },
  {
    value: "PEDAGIO_100",
    desc: "Pedágio 100%",
  },
  {
    value: "PEDAGIO_100_PROFESSOR",
    desc: "Pedágio 100% Professor",
  },
  {
    value: "IDADE_MINIMA_PROGRESSIVA",
    desc: "Idade mínima progressiva",
  },
  {
    value: "IDADE_MINIMA_PROGRESSIVA_PROFESSOR",
    desc: "Idade mínima progressiva Professor",
  },
  {
    value: "RURAL",
    desc: "Rural",
  },
  {
    value: "MP_905_2019",
    desc: "MP 905/2019",
  },
  {
    value: "ACIDENTE_TRABALHO",
    desc: "Acidente de Trabalho",
  },
];

export const alertasCnis = [
  {
    value: "PERIODO_INICIA_ANTES_DE_JUL_94",
    desc: "Periodo inicial antes de Jul 94",
  },
  {
    value: "CONTRIBUICAO_ANTES_DE_JUL_94",
    desc: "Contribuição anterior a Jul 94",
  },
  {
    value: "TEMPO_DE_CONTRIBUICAO_MENOR_QUE_NUMERO_DE_CONTRIBUICOES_ESPERADO",
    desc: "Algumas contribuições parecem estar faltando",
  },
  { value: "VINCULO_SEM_DATA_DE_FIM", desc: "Vinculo sem data fim" },
  {
    value: "SALARIO_DE_CONTRIBUICAO_INFERIOR_AO_PISO",
    desc: "Salário de contribuição inferior ao mínimo",
  },
  {
    value: "TEMPO_DE_CONTRIBUICAO_MAOIR_QUE_NUMERO_DE_CONTRIBUICOES_ESPERADO",
    desc: "Algumas contribuições parecem estar faltando",
  },
  {
    value: "SALARIO_DE_CONTRIBUICAO_SUPERIOR_AO_TETO",
    desc: "Salário de contribuição superior ao teto",
  },
];
export const categorias = [
  { value: "TRABALHADOR_RURAL", desc: "Rural" },
  { value: "SERVICO_MILITAR", desc: "Militar" },
  { value: "ESCOLA_TECNICA", desc: "Escola Técnica" },
  { value: "SERVICO_PUBLICO", desc: "Serviço público (CTC)" },
  { value: "PESSOA_COM_DEFICIENCIA", desc: "Pessoa com deficiência" },
  { value: "TRABALHO_NO_EXTERIOR", desc: "Trabalho no exterior" },
  { value: "CONTRIBUINTE_INDIVIDUAL", desc: "Contribuinte individual" },
  { value: "GPS_NAO_COMPUTADO", desc: "GPS não computado" },
  { tag: "ATIVIDADE_ESPECIAL", desc: "Atividade Especial" },
  { tag: "INCAPACIDADE_PARA_TRABALHO", desc: "Incapacidade para o Trabalho" },
  { tag: "ATIVIDADE_CONCOMITANTE", desc: "Atividades Concomitantes" },
  {
    value: "CONTRATO_DE_TRABALHO_COM_ERRO_NO_CNIS",
    desc: "Contrato de - trabalho com erro no CNIS",
  },
];
export const tagsPeticao = [
  { tag: "TRABALHADOR_RURAL", desc: "Trabalhador Rural" },
  { tag: "SERVICO_MILITAR", desc: "Serviço Militar" },
  { tag: "ESCOLA_TECNICA", desc: "Escola Técnica" },
  { tag: "SERVICO_PUBLICO", desc: "Serviço Público" },
  { tag: "PESSOA_COM_DEFICIENCIA", desc: "Pessoa com deficiência" },
  { tag: "TRABALHO_NO_EXTERIOR", desc: "Trabalho no Exterior" },
  { tag: "CONTRIBUINTE_INDIVIDUAL", desc: "Contribuinte Individual" },
  { tag: "GPS_NAO_COMPUTADO", desc: "GPS não computado" },
  { tag: "ATIVIDADE_ESPECIAL", desc: "Atividade Especial" },
  { tag: "INCAPACIDADE_PARA_TRABALHO", desc: "Incapacidade para o Trabalho" },
  { tag: "ATIVIDADE_CONCOMITANTE", desc: "Atividades Concomitantes" },
  {
    tag: "CONTRATO_DE_TRABALHO_COM_ERRO_NO_CNIS",
    desc: "Contrato de - trabalho com erro no CNIS",
  },
];
export const competencia = [
  { value: "FEDERAL", desc: "Federal" },
  { value: "ESTADUAL", desc: "Estadual" },
  { value: "OUTROS", desc: "Outros" },
];

export const escolaridade = [
  { value: "NENHUM", desc: "Nenhum" },
  {
    value: "ENSINO_FUNDAMENTAL_INCOMPLETO",
    desc: "Ensino Fundamental Incompleto",
  },
  { value: "ENSINO_FUNDAMENTAL_COMPLETO", desc: "Ensino Fundamental Completo" },
  { value: "ENSINO_MEDIO_INCOMPLETO", desc: "Ensino Medio Incompleto" },
  { value: "ENSINO_MEDIO_COMPLETO", desc: "Ensino Medio Completo" },
  { value: "ENSINO_SUPERIOR_INCOMPLETO", desc: "Ensino Superior Incompleto" },
  { value: "ENSINO_SUPERIOR_COMPLETO", desc: "Ensino Superior Completo" },
  { value: "POS_GRADUADO", desc: "Pos Graduado" },
  { value: "MESTRADO", desc: "Mestrado" },
  { value: "DOUTORADO", desc: "Doutorado" },
  { value: "POS_DOUTORADO", desc: "Pos Doutorado" },
];

export const tiposDeBeneficio = [
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO",
    desc: "Aposentadoria por tempo de contribuição",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS",
    desc: "Aposentadoria por tempo de contribuição pontos",
  },
  {
    value: "APOSENTADORIA_POR_IDADE_RURAL",
    desc: "Aposentadoria por idade (Rural)",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PROFESSOR",
    desc: "Aposentadoria por Tempo de Contribuição - Professor",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS_PROFESSOR",
    desc: "Aposentadoria por Tempo de Contribuição - Professor (Pontos)",
  },
  {
    value: "APOSENTADORIA_POR_IDADE_DEFICIENCIA",
    desc: "Aposentadoria por Idade da Pessoa com Deficiência",
  },
  {
    value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_DEFICIENCIA",
    desc: "Aposentadoria por Tempo de Contribuição da Pessoa com Deficiência",
  },
  { value: "APOSENTADORIA_POR_IDADE", desc: "Aposentadoria por idade" },
  { value: "APOSENTADORIA_ESPECIAL", desc: "Aposentadoria especial" },
  { value: "APOSENTADORIA_POR_INVALIDEZ", desc: "Aposentadoria por invalidez" },
  { value: "AUXILIO_DOENCA", desc: "Auxílio-Doença" },
  { value: "AUXILIO_ACIDENTE", desc: "Auxílio-Acidente" },
  { value: "ASSISTENCIAL", desc: "Assistencial" },
  { value: "PENSAO_POR_MORTE", desc: "Pensão por morte" },
  { value: "SALARIO_MATERNIDADE", desc: "Salário maternidade" },
  { value: "AUXILIO_RECLUSAO", desc: "Auxilio reclusão" },
  {
    value: "APOSENTADORIA_POR_INCAPACIDADE",
    desc: "Aposentadoria por incapacidade permanente",
  },
];

export const tiposProcesso = [
  { value: "ADMINISTRATIVO", desc: "Administrativo" },
  { value: "JUDICIAL", desc: "Judicial" },
  { value: "OUTROS", desc: "Outros" },
];

export const tiposPeticao = [
  {
    value: "ACORDO_CONTRAPROPOSTA",
    desc: "Acordo - Contraproposta",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "ANALISES_DE_APOSENTADORIA",
    desc: "Análises de Aposentadoria",
    tipoDeProcesso: "OUTROS",
  },
  {
    value: "ANTECIPACAO_DE_TUTELA",
    desc: "Antecipação de Tutela",
    tipoDeProcesso: "JUDICIAL",
  },
  { value: "CONTESTACOES", desc: "Contestações", tipoDeProcesso: "JUDICIAL" },
  { value: "CONTRARRAZOES", desc: "Contrarrazões", tipoDeProcesso: "JUDICIAL" },
  {
    value: "CONTRARRAZOES_ADMINISTRATIVAS",
    desc: "Contrarrazões Administrativas",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "CUMPRIMENTO_DE_SENTENCA",
    desc: "Cumprimento de sentença",
    tipoDeProcesso: "JUDICIAL",
  },
  { value: "DECLARACOES", desc: "Declarações", tipoDeProcesso: "JUDICIAL" },
  {
    value: "DEFESAS_ADMINISTRATIVAS",
    desc: "Defesas Administrativas",
    tipoDeProcesso: "ADMINISTRATIVO",
  },
  { value: "MANIFESTACOES", desc: "Manifestações", tipoDeProcesso: "JUDICIAL" },
  { value: "PETICOES", desc: "Petições", tipoDeProcesso: "JUDICIAL" },
  {
    value: "PETICOES_ADMINISTRATIVAS",
    desc: "Petições Administrativas",
    tipoDeProcesso: "ADMINISTRATIVO",
  },
  {
    value: "PETICOES_INICIAIS",
    desc: "Petições Iniciais",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "PROPOSTA_DE_ACORDO",
    desc: "Propostas de Acordo",
    tipoDeProcesso: "JUDICIAL",
  },
  { value: "RECURSOS", desc: "Recursos", tipoDeProcesso: "JUDICIAL" },
  {
    value: "RECURSO_ADMINISTRATIVO",
    desc: "Recursos Administrativos",
    tipoDeProcesso: "ADMINISTRATIVO",
  },
  { value: "REPLICAS", desc: "Réplicas", tipoDeProcesso: "JUDICIAL" },
  {
    value: "REQUERIMENTO_ADMINISTRATIVO",
    desc: "Requerimento Administrativo",
    tipoDeProcesso: "ADMINISTRATIVO",
  },
  {
    value: "RECURSO_DE_APELACAO",
    desc: "Recurso de Apelação",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "RECURSO_INOMINADO",
    desc: "Recurso Inominado",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "RECURSO_ESPECIAL",
    desc: "Recurso Especial",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "RECURSO_EXTRAORDINARIO",
    desc: "Recurso Extraordinário",
    tipoDeProcesso: "JUDICIAL",
  },
  { value: "AGRAVOS", desc: "Agravos", tipoDeProcesso: "JUDICIAL" },
  {
    value: "AGRAVO_DE_INSTRUMENTO",
    desc: "Agravo de Instrumento",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "AGRAVO_INTERNO",
    desc: "Agravo Interno",
    tipoDeProcesso: "JUDICIAL",
  },
  { value: "EMBARGOS", desc: "Embargos", tipoDeProcesso: "JUDICIAL" },
  {
    value: "EMBARGOS_DE_DECLARACAO",
    desc: "Embargos de Declaração",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "EMBARGOS_INFRIGENTES",
    desc: "Embargos Infrigentes",
    tipoDeProcesso: "JUDICIAL",
  },
  {
    value: "INCIDENTE_DE_UNIFORMIZACAO",
    desc: "Incidente de Uniformização",
    tipoDeProcesso: "JUDICIAL",
  },
];

export const profissoesUsuario = [
  {
    id: "ADVOGADO",
    desc: "Advogado",
  },
  {
    id: "BACHAREL_EM_DIREITO",
    desc: "Bacharel em Direito",
  },
  {
    id: "ACADEMICO_DO_DIREITO",
    desc: "Acadêmico de Direito",
  },
  {
    id: "ESTAGIARIO",
    desc: "Estagiário",
  },
  {
    id: "SERVIDOR_PUBLICO",
    desc: "Servidor Público",
  },
  {
    id: "CONTADOR",
    desc: "Contador",
  },
  {
    id: "APOSENTADO_OU_SEGURADO",
    desc: "Aposentado ou segurado",
  },
  {
    id: "OUTRO",
    desc: "Outro",
  },
];

export const profissoes = {
  primeira: [
    {
      id: "ADVOGADO",
      desc: "Advogado",
    },
    {
      id: "BACHAREL_EM_DIREITO",
      desc: "Bacharel em Direito",
    },
    {
      id: "ACADEMICO_DO_DIREITO",
      desc: "Acadêmico de Direito",
    },
    {
      id: "ESTAGIARIO",
      desc: "Estagiário",
    },
  ],
  segunda: [
    {
      id: "SERVIDOR_PUBLICO",
      desc: "Servidor Público",
    },
    {
      id: "CONTADOR",
      desc: "Contador",
    },
    {
      id: "APOSENTADO_OU_SEGURADO",
      desc: "Aposentado ou segurado",
    },
    {
      id: "OUTRO",
      desc: "Outro",
    },
  ],
};

export const fichaCliente = [
  { tag: "ZONA_RURAL", desc: "Zona rural" },
  { tag: "EXERCITO", desc: "Exército" },
  { tag: "ESCOLA_TECNICA", desc: "Escola técnica" },
  { tag: "JOVEM_APRENDIZ", desc: "Jovem aprendiz" },
  { tag: "SERVICO_PUBLICO", desc: "Serviço público" },
  { tag: "CONTRATO_EXPERIENCIA", desc: "Contrato de experiência" },
  { tag: "CONTRIBUINTE_INDIVIDUAL", desc: "Contribuinte individual" },
  { tag: "TRABALHO_NO_EXTERIOR", desc: "Trabalhou fora do Brasil" },
];

export const statusCobranca = [
  { status: "PENDENTE", desc: "AGUARDANDO PAGAMENTO" },
  { status: "PAGA", desc: "PAGAMENTO EFETUADO" },
  { status: "CANCELADA", desc: "PAGAMENTO CANCELADO" },
  { status: "PROCESSANDO", desc: "AGUARDANDO PAGAMENTO" },
  { status: "ANALISE_RISCO", desc: "AGUARDANDO ANÁLISE" },
];

export const statusTransacao = [
  { status: "PROCESSANDO", desc: "Transação aguardando processamento" },
  { status: "SUCESSO", desc: "Transação paga com sucesso" },
  { status: "REJEITADA", desc: "" },
  { status: "FALHA", desc: "Falha no pagamento da transação" },
  { status: "TIMEOUT", desc: "Tempo esgotado" },
  { status: "AGUARDANDO", desc: "Transação aguardando pagamento" },
  { status: "CANCELADA", desc: "Transação cancelada" },
  { status: "ANALISE_RISCO", desc: "Aguardando análise de risco" },
  { status: "ESTORNO", desc: "Cobrança estornada com sucesso" },
];

export const statusAssinatura = [
  { value: "ATIVA", desc: "Ativa" },
  { value: "CANCELADA", desc: "Cancelada" },
];

export const statusAcesso = [
  { value: "ATIVO", desc: "Ativo" },
  { value: "SUSPENSO", desc: "Suspenso" },
];

export const tipoDeRevisao = [
  {
    value: "REVISAO_EXCLUSAO_FATOR_PREVIDENCIARIO_PROFESSOR",
    desc: "Revisão da Exclusão do Fator Previdenciário do Professor",
  },
  {
    value: "REVISAO_INCLUSAO_13_FERIAS",
    desc: "Revisão da Inclusão do 13º Salário e Férias",
  },
  { value: "REVISAO_MELHOR_DIB", desc: "Revisão da Melhor DIB" },
  { value: "REVISAO_ORTN_OTN", desc: "Revisão da ORTN-OTN" },
  { value: "REVISAO_SUMULA_260_TFR", desc: "Revisão da Súmula 260 do TFR" },
  { value: "REVISAO_VIDA_TODA", desc: "Revisão da Vida Toda ou Vida Inteira" },
  { value: "REVISAO_ADICIONAL_25", desc: "Revisão do adicional de 25%" },
  {
    value: "REVISAO_ARTIGO_29_INCISO_II",
    desc: "Revisão do artigo 29, inciso II, da Lei 8.213/91",
  },
  {
    value: "REVISAO_AUXILIO_ACIDENTE_SUPLEMENTAR_RMI",
    desc: "Revisão do Auxílio-Acidente e Auxílio-Suplementar no Cálculo da RMI",
  },
  { value: "REVISAO_BURACO_NEGRO", desc: "Revisão do Buraco Negro" },
  { value: "REVISAO_BURACO_VERDE", desc: "Revisão do Buraco Verde" },
  { value: "REVISAO_IRSM", desc: "Revisão do IRSM de fevereiro de 1994" },
  { value: "REVISAO_MELHOR_BENEFICIO", desc: "Revisão do Melhor Benefício" },
  { value: "REVISAO_PRIMEIRO_REAJUSTE", desc: "Revisão do Primeiro Reajuste" },
  {
    value: "REVISAO_RECONHECIMENTO_TEMPO_ESPECIAL",
    desc: "Revisão do Reconhecimento de Tempo de Serviço Especial",
  },
  { value: "REVISAO_SUBTETO", desc: "Revisão do Sub-Teto" },
  {
    value: "REVISAO_TETO_EC20_EC41",
    desc: "Revisão do Teto (ECs 20/98 e 41/03)",
  },
  {
    value: "REVISAO_ATIVIDADES_CONCOMITANTES",
    desc: "Revisão das Atividades Concomitantes",
  },
];
