import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";

import "antd/dist/antd.css";
import "assets/prev-iconset/feather/css/iconfont.css";
import "./index.css";
import "./App.css";
import { isAuthenticated } from "core/prevFunctions";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";
import WebFont from "webfontloader";
import 'animate.css';

WebFont.load({
  google: {
    families: ["Source Sans Pro:300,400,600", "sans-serif"]
  }
});

if (isAuthenticated()) {
  configureStore().dispatch({ type: "AUTHENTICATED" });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
