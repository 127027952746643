import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

// Log do redux
const loggerMiddleware = createLogger();

export default function configureStore() {
  const createStoreWithMiddleware = applyMiddleware(thunk, loggerMiddleware)(
    createStore
  );
  return createStoreWithMiddleware(rootReducer);
}
