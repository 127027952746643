export const contas = (state = {}, action) => {
  switch (action.type) {
    case "CONTA_LOADING":
      return {
        ...state,
        loadingConta: !!!state.loadingConta
      };
    case "CONTA_RESUMO":
      return {
        ...state,
        contaResumo: action.payload
      };

    case "CONTA_ESCRITORIO":
      return {
        ...state,
        contaEscritorio: action.payload
      };
    case "CONTA_INFO":
      return {
        ...state,
        contaInfo: action.payload
      };
    case "CONTA_ASSINATURA":
      return {
        ...state,
        contaAssinatura: action.payload
      };
    case "CONTA_TIMELINE":
      return {
        ...state,
        contaTimeline: action.payload
      };
    case "CONTA_TIMELINE_COMPLETA":
      return {
        ...state,
        contaTimelineCompleta: action.payload
      };
    case "CONTAS_LOADING":
      return {
        ...state,
        loading: true
      };
    case "LIST_CONTAS":
      return {
        ...action.payload,
        loading: false
      };
    case "ESTORNO_LOADING":
      return {
        ...state,
        estornoLoading: action.payload
      };

    default:
      return state;
  }
};

export const sessao = (state = {}, action) => {
  switch (action.type) {
    case "SALVAR_SESSOES":
      return {
        ...state,
        selecionadas: action.payload
      };
    case "LIMPAR_SESSOES":
      delete state.selecionadas
      return {
        ...state,
      };
    default:
      return state;
  }
}