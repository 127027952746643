import {
  TIPOS_DE_ARQUIVOS,
  LOADING_STATUS_TIPOS_ARQUIVOS,
  LOADING_GERAL,
  COLLAPSE_MENU,
  TEST_PAYWALL,
  UPDATE_OMNI_SEARCH,
  TOGGLE_OMNI_SEARCH
} from 'actions/utils'

const initalState = {
  loadingGeral: false,
  showPayWall: false,
  collapseMenu: false,
  omniSearch: { isSearching: false, searchQuery: '', toggleClean: false},
  omniSearchOpen: false,
  tiposDeArquivos: {
    loading: false,
    lista: []
  }
}

const utilsReducer = (state = initalState, action) => {
  switch(action.type) {
    case TIPOS_DE_ARQUIVOS:
      return {
        ...state,
        tiposDeArquivos: {...state.tiposDeArquivos, lista: action.tipos},
      }
    case LOADING_STATUS_TIPOS_ARQUIVOS:
      return {
        ...state,
        tiposDeArquivos: {...state.tiposDeArquivos, loading: action.status},
      }
    case LOADING_GERAL:
      return{
        ...state,
        loadingGeral: action.loading
      }
    case TEST_PAYWALL:
      return{
        ...state,
        showPayWall: action.showPayWall
      }
    case COLLAPSE_MENU:
      return{
        ...state,
        collapseMenu: action.collapse
      }
    case UPDATE_OMNI_SEARCH:
      return{
        ...state,
        omniSearch: {
          ...state.omniSearch,
          ...action.omniSearch,
          toggleClean: action.omniSearch.searchQuery === '' ? !state.omniSearch.toggleClean : state.omniSearch.toggleClean 
        }
      }
    case TOGGLE_OMNI_SEARCH:
      return{
        ...state,
        omniSearchOpen: !state.omniSearchOpen
      }
    default:
      return state
  }
}

export default utilsReducer;
